// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("./../node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-ca-js": () => import("./../src/pages/404.ca.js" /* webpackChunkName: "component---src-pages-404-ca-js" */),
  "component---src-pages-404-en-js": () => import("./../src/pages/404.en.js" /* webpackChunkName: "component---src-pages-404-en-js" */),
  "component---src-pages-404-es-js": () => import("./../src/pages/404.es.js" /* webpackChunkName: "component---src-pages-404-es-js" */),
  "component---src-pages-404-fr-js": () => import("./../src/pages/404.fr.js" /* webpackChunkName: "component---src-pages-404-fr-js" */),
  "component---src-pages-carta-ca-js": () => import("./../src/pages/carta.ca.js" /* webpackChunkName: "component---src-pages-carta-ca-js" */),
  "component---src-pages-carta-en-js": () => import("./../src/pages/carta.en.js" /* webpackChunkName: "component---src-pages-carta-en-js" */),
  "component---src-pages-carta-es-js": () => import("./../src/pages/carta.es.js" /* webpackChunkName: "component---src-pages-carta-es-js" */),
  "component---src-pages-carta-fr-js": () => import("./../src/pages/carta.fr.js" /* webpackChunkName: "component---src-pages-carta-fr-js" */),
  "component---src-pages-contacte-ca-js": () => import("./../src/pages/contacte.ca.js" /* webpackChunkName: "component---src-pages-contacte-ca-js" */),
  "component---src-pages-contacte-en-js": () => import("./../src/pages/contacte.en.js" /* webpackChunkName: "component---src-pages-contacte-en-js" */),
  "component---src-pages-contacte-es-js": () => import("./../src/pages/contacte.es.js" /* webpackChunkName: "component---src-pages-contacte-es-js" */),
  "component---src-pages-contacte-fr-js": () => import("./../src/pages/contacte.fr.js" /* webpackChunkName: "component---src-pages-contacte-fr-js" */),
  "component---src-pages-cuina-ca-js": () => import("./../src/pages/cuina.ca.js" /* webpackChunkName: "component---src-pages-cuina-ca-js" */),
  "component---src-pages-cuina-en-js": () => import("./../src/pages/cuina.en.js" /* webpackChunkName: "component---src-pages-cuina-en-js" */),
  "component---src-pages-cuina-es-js": () => import("./../src/pages/cuina.es.js" /* webpackChunkName: "component---src-pages-cuina-es-js" */),
  "component---src-pages-cuina-fr-js": () => import("./../src/pages/cuina.fr.js" /* webpackChunkName: "component---src-pages-cuina-fr-js" */),
  "component---src-pages-espais-ca-js": () => import("./../src/pages/espais.ca.js" /* webpackChunkName: "component---src-pages-espais-ca-js" */),
  "component---src-pages-espais-en-js": () => import("./../src/pages/espais.en.js" /* webpackChunkName: "component---src-pages-espais-en-js" */),
  "component---src-pages-espais-es-js": () => import("./../src/pages/espais.es.js" /* webpackChunkName: "component---src-pages-espais-es-js" */),
  "component---src-pages-espais-fr-js": () => import("./../src/pages/espais.fr.js" /* webpackChunkName: "component---src-pages-espais-fr-js" */),
  "component---src-pages-index-ca-js": () => import("./../src/pages/index.ca.js" /* webpackChunkName: "component---src-pages-index-ca-js" */),
  "component---src-pages-index-en-js": () => import("./../src/pages/index.en.js" /* webpackChunkName: "component---src-pages-index-en-js" */),
  "component---src-pages-index-es-js": () => import("./../src/pages/index.es.js" /* webpackChunkName: "component---src-pages-index-es-js" */),
  "component---src-pages-index-fr-js": () => import("./../src/pages/index.fr.js" /* webpackChunkName: "component---src-pages-index-fr-js" */),
  "component---src-pages-menu-ca-js": () => import("./../src/pages/menu.ca.js" /* webpackChunkName: "component---src-pages-menu-ca-js" */),
  "component---src-pages-menu-en-js": () => import("./../src/pages/menu.en.js" /* webpackChunkName: "component---src-pages-menu-en-js" */),
  "component---src-pages-menu-es-js": () => import("./../src/pages/menu.es.js" /* webpackChunkName: "component---src-pages-menu-es-js" */),
  "component---src-pages-menu-fr-js": () => import("./../src/pages/menu.fr.js" /* webpackChunkName: "component---src-pages-menu-fr-js" */),
  "component---src-pages-noticies-ca-js": () => import("./../src/pages/noticies.ca.js" /* webpackChunkName: "component---src-pages-noticies-ca-js" */),
  "component---src-pages-noticies-en-js": () => import("./../src/pages/noticies.en.js" /* webpackChunkName: "component---src-pages-noticies-en-js" */),
  "component---src-pages-noticies-es-js": () => import("./../src/pages/noticies.es.js" /* webpackChunkName: "component---src-pages-noticies-es-js" */),
  "component---src-pages-noticies-fr-js": () => import("./../src/pages/noticies.fr.js" /* webpackChunkName: "component---src-pages-noticies-fr-js" */),
  "component---src-pages-origens-ca-js": () => import("./../src/pages/origens.ca.js" /* webpackChunkName: "component---src-pages-origens-ca-js" */),
  "component---src-pages-origens-en-js": () => import("./../src/pages/origens.en.js" /* webpackChunkName: "component---src-pages-origens-en-js" */),
  "component---src-pages-origens-es-js": () => import("./../src/pages/origens.es.js" /* webpackChunkName: "component---src-pages-origens-es-js" */),
  "component---src-pages-origens-fr-js": () => import("./../src/pages/origens.fr.js" /* webpackChunkName: "component---src-pages-origens-fr-js" */),
  "component---src-templates-blog-entry-js": () => import("./../src/templates/blog-entry.js" /* webpackChunkName: "component---src-templates-blog-entry-js" */),
  "component---src-templates-homepage-js": () => import("./../src/templates/homepage.js" /* webpackChunkName: "component---src-templates-homepage-js" */)
}

