module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-57659736-1"},
    },{
      plugin: require('../node_modules/gatsby-source-prismic/gatsby-browser.js'),
      options: {"plugins":[],"repositoryName":"calperedelmasetweb","accessToken":"MC5YcUhRQnhBQUFDRUFTYXZS.UO-_ve-_vVTvv71v77-977-9LO-_vWPvv70F77-9UwPvv71QK2knIu-_ve-_vRHvv73vv73vv73vv71K77-977-9","schemas":{"homepage":{"Main":{"main_title":{"type":"StructuredText","config":{"single":"heading1, heading2, heading3, heading4, heading5, heading6","label":"main title"}},"main_background_image":{"type":"Image","config":{"constraint":{},"thumbnails":[],"label":"Main Background Image"}},"body":{"type":"Slices","fieldset":"Slice zone","config":{"labels":{"homepage_grid":[]},"choices":{"homepage_grid":{"type":"Slice","fieldset":"Homepage Grid","description":"Homepage grid to publish new content","icon":"fiber_new","display":"grid","non-repeat":{"news_title":{"type":"StructuredText","config":{"single":"heading1, heading2, heading3, heading4, heading5, heading6","label":"News Title","placeholder":"add the title of what you want to highlight"}}},"repeat":{"image_preview":{"type":"Image","config":{"constraint":{},"thumbnails":[],"label":"Image preview"}}}}}}}}}}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Restaurant Cal Pere del Maset","short_name":"Cal Pere Maset","start_url":"/","background_color":"#663399","theme_color":"#663399","display":"minimal-ui","icon":"src/images/imgLogoCalPere.png"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
